import validate from 'validate.js';

const fieldValidaor = function () {
  const form = document.querySelector('.js-form')

  if ( !form ) {
    return;
  }

  const constraints = {
    email: {
      email: true,
      presence: {
        allowEmpty: false,
      },
    },
    phone: {
      presence: {
        allowEmpty: false,
      },
      length: {
        minimum: 5,
      },
    },
    name: {
      presence: {
        allowEmpty: false,
      },
    },
    message: {
      presence: {
        allowEmpty: false,
      },
    },
    agreement: {
      presence: {
        message: '^You need to check the checkbox',
      },
      inclusion: {
        within: [true],
        message: '^You need to check the checkbox',
      },
    },
  };

  const fields =  form.querySelectorAll('.js-validate');

  fields.forEach(function (field) {
    field.addEventListener('input', function (params) {
      const type = field.getAttribute('name') || field.getAttribute('type') ;

      if ( ! type ) {
        return;
      }

      let value = field.value.trim();

      if ( type == 'checkbox') {
        value = field.checked;
      }

      let result = validate({ [type]: value }, constraints);

      if ( typeof result[type] !== 'undefined' ) {
        field.classList.add('invalid');
        field.classList.remove('valid');
      } else {
        field.classList.remove('invalid');
        field.classList.add('valid');
      }
    })
  })

  form.addEventListener('submit', function (e) {
    const values = validate.collectFormValues(form);
    let validation = validate(values, constraints)

    if ( typeof validation != 'undefined' ) {
      e.preventDefault();

      fields.forEach(function (field) {
        const type = field.getAttribute('name') || field.getAttribute('type') ;

        let value = field.value.trim();

        if ( type == 'checkbox') {
          value = field.checked;
        }

        let result = validate({ [type]: value }, constraints);

        if ( typeof result[type] !== 'undefined' ) {
          field.classList.add('invalid');
          field.classList.remove('valid');
        }
      })
    }
  })
}

fieldValidaor();
