require('./bootstrap');
import './polyfills/object-fit';
import AOS from 'aos';

import './modules/mapUI.js';
import './modules/handleHeader.js';
import './modules/handleSliders.js';
import './modules/textAreaExpand';
import './modules/formValidation';

AOS.init({
  once: true,
});
