function handleNavToggler() {
  const navToggler = document.querySelector('.js-nav-toggler');
  const body = document.querySelector('body');

  navToggler.addEventListener('click', function() {
    const navTogglerActive = document.querySelector('.js-nav-toggler[aria-expanded="true"]');

    if (navTogglerActive) {
      body.classList.add('is-locked');
    } else {
      body.classList.remove('is-locked');
    }

  });
};


const headerHandlerScroll = function () {
  const header = document.querySelector('.js-header');

  let lastScrollTop = 0;

  window.addEventListener('scroll', function () {
    let position = window.scrollY;
    if (position > 250 && !header.classList.contains('is-scroll')) {
      header.classList.add('is-scroll');
    } else if (position < 250 && header.classList.contains('is-scroll')) {
      header.classList.remove('is-scroll');
    }

    lastScrollTop = position;
  });
};

headerHandlerScroll();
handleNavToggler();
