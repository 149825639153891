
function handleSliders() {

	if (document.querySelectorAll('[class*="js-slider"]').length == 0) {
		return
	}

	if (
    document.querySelectorAll('.js-slider-hero').length > 0 &&
    document.querySelectorAll('.js-slider-hero-thumbs').length > 0
  ) {
		const sliderHero = tns({
			container: '.js-slider-hero',
	    items: 1,
	    slideBy: 'page',
	    autoplay: true,
	    controls: false,
	    autoplayButton: false,
	    autoplayTimeout: 4000,
	    autoplayButtonOutput: false,
	    controlsContainer: '.js-slider-hero',
			navContainer: '.js-slider-hero-thumbs',
			navAsThumbnails: true,
		});

		const sliderHeroThumbs = tns({
			container: '.js-slider-hero-thumbs',
	    items: 3,
	    slideBy: 'page',
	    autoplay: true,
	    controls: false,
	    gutter: 30,
	    autoplayButton: false,
	    autoplayButtonOutput: false,
		});

		const thumbs = document.querySelectorAll('.js-slider-hero-thumbs .slider__slide')

		for (const thumb of thumbs) {
			thumb.addEventListener('click', function(e) {
				setTimeout(function() {
					sliderHero.play();
					sliderHeroThumbs.play();
				}, 10)
			})

		}
	}


	if (document.querySelectorAll('.js-slider-gallery').length > 0) {
		const sliderGallery = tns({
	    container: '.js-slider-gallery',
	    items: 1,
	    slideBy: 'page',
	    controls: true,
	    autoplayButton: false,
	    autoplayTimeout: 3000,
	    autoplayButtonOutput: false,
	    edgePadding: 255,
	    swipeAngle: false,
	    slideBy: "page",
	    center: true,
		nav:false,
	    responsive: {
	        "320": {
	            edgePadding: 40,
	            gutter: 20,
	        },
	        "768": {
	            edgePadding: 50,
	            gutter: 20,
	        },
	        "1024": {
	            edgePadding: 155,
	            gutter: 80,
	        },
	        "1200": {
	            edgePadding: 255,
	            gutter: 0,
	        },
	        "1500": {
	            gutter: 0,
	        },
	    },
		});
		window.addEventListener('load', function () {		
			var count = sliderGallery.getInfo().slideCount;
			document.getElementById('slide-counter').innerHTML = count
			// bind function to event
			sliderGallery.events.on('indexChanged', function (info) {
				var index = info.displayIndex;
				document.getElementById('slide-index').innerHTML = index + ' '
			});
		})
	}

	if (
    document.querySelectorAll('.js-slider-history').length > 0 &&
    document.querySelectorAll('.js-slider-history-thumbs').length > 0
  ) {
		const sliderHistory = tns({
			container: '.js-slider-history',
			items: 1,
			slideBy: 'page',
			controls: true,
			autoplayButton: false,
			loop: false,
			autoplayButtonOutput: false,
			navContainer: '.js-slider-history-thumbs',
			navAsThumbnails: true,
		});


		const sliderHistoryThumbs = tns({
      container: '.js-slider-history-thumbs',
      items: 3,
      slideBy: 'page',
      controls: false,
			loop: false,
      gutter: 30,
			nav: false,
      autoplayButton: false,
      autoplayButtonOutput: false,
    });
	}
	if (document.querySelectorAll('.js-slider-sponsor').length > 0) {
		const sliderSponsor = tns({
	    container: '.js-slider-sponsor',
	    slideBy: 1,
		rewind:false,
		loop:true,
		nav:false,
		autoplay:true,
	    controls: false,
	    autoplayButton: false,
		autoplayTimeout:3000,
		speed:1000,
		preventActionWhenRunning:true,
	    autoplayButtonOutput: false,
	    edgePadding: 255,
	    swipeAngle: false,
	    responsive: {
	        "320": {
	            edgePadding: 40,
	            gutter: 20,
				items: 1,
	        },
	        "768": {
	            edgePadding: 50,
	            gutter: 20,
				items: 2,
	        },
	        "1024": {
	            edgePadding: 155,
	            gutter: 80,
				items:3,
	        },
	        "1200": {
	            edgePadding: 255,
	            gutter: 0,
				items:3
	        },
	        "1500": {
	            gutter: 0,
				items: 3,
	        },
	    },
		});
	}};

handleSliders();
